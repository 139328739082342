import Swal from 'sweetalert2';

export default {
    showPopup (request, thenFunction) {
        Swal.fire({
            icon: request.icon,
            title: request.title === undefined ? null : '<div style="font-size: 20px; font-weight: 500">' + request.title + '</div>',
            html: request.text === undefined ? null : '<div style="font-size: 14px; font-weight: 400">' + request.text + '</div>',
            showCancelButton: false,
            showCloseButton: true,
            showConfirmButton: false,
            backdrop: request.backdrop !== undefined ? request.backdrop : request.icon === undefined ? false : request.icon === 'error',
            allowOutsideClick: false,
            width: request.width
        }).then(function () {
            if (thenFunction) thenFunction();
        });
    },
    close () {
        Swal.close();
    },
    showRefreshUrl (request) {
        Swal.fire({
            icon: 'info',
            html: '<div style="font-size: 14px; font-weight: 400">' + request.text + '</div>',
            showCancelButton: false,
            showCloseButton: true,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false
        }).then(function () {
            window.location.reload();
        });
    },
    showConfirmationCheck (request) {
        return Swal.fire({
            icon: request.icon,
            title: request.title === undefined ? null : '<div style="font-size: 20px; font-weight: 500">' + request.title + '</div>',
            html: request.text === undefined ? null : '<div style="font-size: 14px; font-weight: 400">' + request.text + '</div>',
            showCancelButton: true,
            showCloseButton: true,
            showConfirmButton: true,
            backdrop: request.backdrop !== undefined ? request.backdrop : request.icon === undefined ? false : request.icon === 'error',
            allowOutsideClick: false,
            width: request.width,
            confirmButtonText: request.confirmButtonText,
            cancelButtonText: request.cancelButtonText,
            confirmButtonColor: 'rgb(0,163,199)',
            cancelButtonColor: 'rgb(223,63,111)'
        });
    }
};
